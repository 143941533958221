import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { MIN_TABLET_MQ, MIN_LARGER_DISPLAY_MQ } from '../utils/presets'

const ContentWrapper = styled.main({
    margin: `10vh 24px`,
  [MIN_TABLET_MQ]: {
    margin: `10vh 48px`,
    display: `flex`,
    height: `80vh`,
    alignItems: `center`
  },
  [MIN_LARGER_DISPLAY_MQ]: {
    margin: `10vh auto`,
    maxWidth: `60vw`,
    padding: `0 10vh`,
  }
})

export const HTMLContent = ({ content, className }) => (
  <ContentWrapper><div dangerouslySetInnerHTML={{ __html: content }} /></ContentWrapper>
)

const Content = ({ content, className }) => (
  <ContentWrapper>{content}</ContentWrapper>
)

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
}

HTMLContent.propTypes = Content.propTypes

export default Content
